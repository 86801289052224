import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { HeaderService, LoadingService } from "./services";
import { Settings } from "./models";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public tarteaucitron: any = null;

  constructor(
    private router: Router,
    private titleService: Title,
    private headerService: HeaderService,
    private loadingService: LoadingService
  ) {
    this.headerService.getSettings().subscribe((setting: Settings) => {
      this.titleService.setTitle(setting.site_name);
    })
  }

  ngOnInit(): void {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.loadingService.startLoading();
        }

        if(event instanceof NavigationEnd) {
          this.loadingService.stopLoading();
        }
      },
      error => {
        this.loadingService.stopLoading();
        console.log(error);
      })
  }

}
